import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  var { options, publicData } = props;
  if (!publicData) {
    return null;
  }
  if (typeof window !== 'undefined') {
    var language = window.localStorage.getItem('lang');
    if (language !== 'EN') {
      console.log(options);
      options = options.map(e => {
        return {
          ...e,
          label: e.polishLabel,
        };
      });
    }
  }
  const selectedOptions = publicData && publicData.amenities ? publicData.amenities : [];
  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.amenities"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  );
};

export default SectionFeaturesMaybe;
